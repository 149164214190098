import {DataTable} from "grommet";
import React, {useCallback, useEffect, useState} from "react";
import {
    Campaign,
    CommentType,
    Influencer,
    InfluencerList,
    InfluencerListInfluencerState,
    Networks
} from "../../types/dbModels";
import FormInput from "../FormInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import chartLogo from "../../assets/images/charte-ethique.png";
import imgVerified from "../../assets/images/verified.png";
import {formatNumber, formatPercentage, generateUid} from "../../helpers/functions";
import CategorySelect
    from "../pages/employee/InfluencerListPage/InfluencerListDetailsPage/Selectors/InfluencerListDetailsCategorySelect";
import FameSelect
    from "../pages/employee/InfluencerListPage/InfluencerListDetailsPage/Selectors/InfluencerListDetailsFameSelect";
import StatusSelect
    from "../pages/employee/InfluencerListPage/InfluencerListDetailsPage/Selectors/InfluencerListDetailsStatusSelect";
import SelectAvailableList from "../select/SelectAvailableList";
import {useFirebase} from "../../contexts/FirebaseContext";
import InfluencerSearchDetailsModal from "../modal/InfluencerSearchDetailsModal";
import {useTranslation} from "react-i18next";
import InfluencerCommentsModal from "../modal/InfluencerCommentsModal";
import PlatformFilter from "../filters/PlatformFilter";
import {toast} from "react-toastify";
import ConfirmMessage from "../modal/ConfirmMessage";

export default function InfluencerListDetailsDatatable({influencerList, updateInfluencerList, campaign, influencers}: {
    influencerList: InfluencerList,
    updateInfluencerList: () => void,
    campaign?: Campaign,
    influencers: Influencer[]
}) {
    const {
        putInfluencerList,
        getInfluencerAvailableStatus
    } = useFirebase();

    const {t} = useTranslation();

    const [datatableSorting, setDatatabbleSorting] = useState<any>();
    const [selectedInfluencer, setSelectedInfluencer] = useState<Influencer>();
    const [selectedInfluencerId, setSelectedInfluencerId] = useState<string>("");
    const [influencerIdToDelete, setInfluencerIdToDelete] = useState<any>();
    const [filterStatus, setFilterStatus] = useState<string>('all');
    const [datatableSearch, setDatatableSearch] = useState<undefined | string>();
    const [filterPlatform, setFilterPlatform] = useState<"none" | Networks>('none');

    const influencerModalName = 'influencer_details_modal';
    const commentModalName = "comment_modal_" + generateUid();

    useEffect(() => {
        if (selectedInfluencerId) {
            (window as any)[commentModalName].showModal();
        }
    }, [selectedInfluencerId]);

    const handleSelectInfluencerComment = (influencerId: string) => {
        if (influencerId === selectedInfluencerId) {
            (window as any)[commentModalName].showModal();
            return;
        }
        setSelectedInfluencerId(influencerId)
    }

    const saveInfluencerList = (data: any) => {
        if (!influencerList) return;

        putInfluencerList(influencerList.id, data).then(() => {
            toast.success(t('list.update_success'));
            updateInfluencerList()
        }).catch(() => {
            toast.error(t('list.update_error'));
        });
    }

    const filterStatusChange = (e: any) => {
        setFilterStatus(e.value);
    }

    const filterInfluencer = useCallback((influencer: Influencer): boolean => {
        if (!datatableSearch || datatableSearch?.length === 0) return true;
        if (!influencerList) return false;
        const influencerState = influencerList.influencersCategories.map(c => c.influencers).flat().find(i => i.userId === influencer.userId);
        if (!influencerState) return false;

        const network = influencerState.network;
        const name = network === "youtube" ? influencer[network]?.profile.handle : influencer[network]?.profile.username;

        return !!(name && name.includes(datatableSearch));

    }, [influencerList, datatableSearch])

    const removeInfluencerFromList = (influencerId: string) => {
        if (!influencerList) return;
        const tmp = {...influencerList};
        tmp.influencersCategories = tmp.influencersCategories.map(cat => {
            let newCat = {...cat};
            newCat.influencers = newCat.influencers.filter(i => i.userId !== influencerId)
            return newCat
        })

        putInfluencerList(influencerList.id, tmp).then(() => {
            toast.success(t('list.update_success'));
            updateInfluencerList()
        }).catch(() => {
            toast.error(t('list.update_error'));
        });
    }

    const handleVisibilityToggle = (influencerState: InfluencerListInfluencerState) => {
        if (!influencerList || !influencerState) return;

        const categoryIndex = influencerList.influencersCategories.findIndex(cat => cat.influencers.findIndex(i => i === influencerState) > -1);

        if (categoryIndex < 0) return;

        const stateIndex = influencerList.influencersCategories[categoryIndex].influencers.findIndex(i => i === influencerState);

        if (stateIndex < 0) return;

        const newInfluenccerList = {...influencerList};
        newInfluenccerList.influencersCategories[categoryIndex].influencers[stateIndex].visible = !influencerState.visible;

        putInfluencerList(influencerList.id, newInfluenccerList)
    }


    const renderDatatable = () => {
        let baseArray: any[] = [
            {
                property: 'q',
                header: "Visible",
                primary: false,
                sortable: false,
                render: (data: Influencer) => {
                    const influencerState = influencerList?.influencersCategories.map(c => c.influencers).flat().find(i => i.userId === data.userId);
                    if (!influencerState) return;

                    return (
                        <div className='flex gap-2 items-center justify-center' style={{width: '70px'}}>
                            <button className='btn btn-ghost'
                                    onClick={handleVisibilityToggle.bind(null, influencerState)}>
                                <FontAwesomeIcon icon={['fas', 'ghost']} size={'2x'}
                                                 color={influencerState.visible ? "#928d8d" : "#FFD400"}/>
                            </button>
                        </div>
                    )
                }
            },
            {
                property: 'network',
                header: "",
                primary: false,
                sortable: false,
                render: (data: Influencer) => {
                    const influencerState = influencerList?.influencersCategories.map(c => c.influencers).flat().find(i => i.userId === data.userId);
                    if (!influencerState) return;

                    return (
                        <div style={{opacity: influencerState.visible ? '1' : '0.5'}}>
                            <div className='flex gap-2 items-center justify-center' style={{width: '70px'}}>
                                <FontAwesomeIcon size="2x" icon={['fab', influencerState.network]}
                                                 color={'#133520'}/>
                                {data.signedCodeOfEthics && (
                                    <img src={chartLogo} alt="Chart logo"
                                         style={{width: '40px', height: "40px"}}/>
                                )}
                            </div>
                        </div>
                    )
                }
            },
            {
                property: 'name',
                header: t("table-name"),
                primary: false,
                sortable: false,
                render: (data: Influencer) => {
                    const influencerState = influencerList?.influencersCategories.map(c => c.influencers).flat().find(i => i.userId === data.userId);
                    if (!influencerState) return;

                    return (
                        <div style={{opacity: influencerState.visible ? '1' : '0.5'}}>
                            <div className="flex flex-row items-center hand" onClick={() => {
                                setSelectedInfluencer(data);
                                (window as any)[influencerModalName].showModal();
                            }}>
                                <div className="avatar mr-2">
                                    <div className="w-12 rounded-full">
                                        <img className='h-44 rounded-xl'
                                             src={data[influencerState.network]?.profile.picture}
                                             alt="Influencer Image"/>
                                    </div>
                                </div>
                                <div className="flex flex-col text-start">
                                    <p className='font-bold'>
                                        {data[influencerState.network]?.profile.fullname}
                                    </p>
                                    <p>{("@" + (data[influencerState.network]?.profile.username || data[influencerState.network]?.profile.handle)) || ""}</p>
                                </div>
                                {data[influencerState.network]?.isVerified && (
                                    <img className='ml-2' src={imgVerified}
                                         alt="profile-verified-badge" width={16} height={16}/>
                                )}
                            </div>
                        </div>
                    )
                }
            },
            {
                property: 'followers',
                header: t("table-followers"),
                primary: false,
                render: (data: Influencer) => {
                    const influencerState = influencerList?.influencersCategories.map(c => c.influencers).flat().find(i => i.userId === data.userId);
                    if (!influencerState) return;

                    return (
                        <div style={{opacity: influencerState.visible ? '1' : '0.5'}}>
                            <p className='text-center'>
                                {formatNumber(data[influencerState.network]?.profile.followers ?? 0)}
                            </p>
                        </div>
                    );
                }
            },
            {
                property: 'engagement',
                header: t("table-engagement"),
                primary: false,
                render: (data: Influencer) => {
                    const influencerState = influencerList?.influencersCategories.map(c => c.influencers).flat().find(i => i.userId === data.userId);
                    if (!influencerState) return;

                    return (
                        <div style={{opacity: influencerState.visible ? '1' : '0.5'}}>
                            <p className='text-center'>
                                {formatPercentage(data[influencerState.network]?.profile.engagementRate ?? 0)}%
                            </p>
                        </div>
                    );
                }
            },

            {
                property: 'comments',
                header: t("comments.comments-title"),
                primary: false,
                render: (data: Influencer) => {
                    const influencerState = influencerList?.influencersCategories.map(c => c.influencers).flat().find(i => i.userId === data.userId);
                    if (!influencerState) return;

                    if (influencerState.comments) {
                        return (
                            <div style={{opacity: influencerState.visible ? '1' : '0.5'}}>
                                <div className='flex justify-center'>
                                    <button
                                        type='button'
                                        className={`nowrap badge ${influencerState.comments.length > 0 ? "badge-error" : ""} badge-outline cursor-pointer`}
                                        onClick={() => {
                                            handleSelectInfluencerComment(data.userId)
                                        }}>💬 {(influencerState.comments as CommentType[]).filter(obj => !obj.internal).length}
                                    </button>
                                </div>
                            </div>
                        )
                    }
                }
            },
        ];

        if (campaign?.status_ext == "validated") {
            baseArray.push(
                {
                    property: 'influencer-state',
                    header: t("table-influencer-state"),
                    primary: false,
                    render: (data: Influencer) => {
                        const influencerState = influencerList?.influencersCategories.map(c => c.influencers).flat().find(i => i.userId === data.userId);
                        if (!influencerState) return;
                        let iconColorClass = {
                            pending: {iconName: "question", color: "secondary"},
                            accepted: {iconName: "check", color: "success"},
                            loved: {iconName: "heart", color: "pink"},
                            rejected: {iconName: "xmark", color: "error"},
                        };

                        const actualIconColorsClass = iconColorClass[influencerState?.state || 'pending'];

                        return (
                            <div style={{opacity: influencerState.visible ? '1' : '0.5'}}>
                                <div className='flex w-100 flex-row justify-center' style={{width: "100%"}}>
                                <span
                                    className={`btn btn-sm btn-active btn-${actualIconColorsClass.color} cursor-default`}>
                                    <FontAwesomeIcon
                                        icon={['fas', actualIconColorsClass.iconName as any]} color={'#fff'}/>
                                </span>
                                </div>
                            </div>
                        );

                    }
                }
            );
        }

        baseArray = [
            ...baseArray,
            {
                property: 'category',
                header: t("table-category"),
                primary: false,
                render: (data: Influencer) => {
                    const influencerState = influencerList?.influencersCategories.map(c => c.influencers).flat().find(i => i.userId === data.userId);
                    if (!influencerState) return;

                    return <CategorySelect influencerList={influencerList}
                                           influencerState={influencerState}
                                           shouldReload={updateInfluencerList}/>

                }
            },
            // {
            //     property: 'fame',
            //     sortable: true,
            //     header: t("table-size"),
            //     render: (data: Influencer) => {
            //         const influencerState = influencerList?.influencersCategories.map(c => c.influencers).flat().find(i => i.userId === data.userId);
            //         if (influencerState) {
            //             return <FameSelect influencerList={influencerList}
            //                                influencerState={influencerState}
            //                                shouldReload={updateInfluencerList}/>
            //         }
            //         return ""
            //     }
            // },
        ];

        if (!influencerList.isTemplate) {
            baseArray.push({
                property: 'statut',
                sortable: false,
                header: t("table-status"),
                render: (data: Influencer) => {
                    const influencerState = influencerList?.influencersCategories.map(c => c.influencers).flat().find(i => i.userId === data.userId);
                    if (influencerState) {
                        return <StatusSelect influencerList={influencerList}
                                             influencerState={influencerState}
                                             shouldReload={updateInfluencerList}/>
                    }
                    return ""
                }
            });
        }

        return [
            ...baseArray,
            {
                property: 'add_to_another_list',
                header: t("table-add-list"),
                primary: false,
                sortable: false,
                render: (data: Influencer) => {
                    const influencerState = influencerList?.influencersCategories.map(c => c.influencers).flat().find(i => i.userId === data.userId);
                    if (!influencerState) return;

                    return <SelectAvailableList influencerId={data.userId}
                                                platform={influencerState.network}
                                                showCreate={false}/>
                }
            },
            {
                property: '',
                header: '',
                primary: false,
                sortable: false,
                render: (data: Influencer) => {
                    return <button className="btn btn-ghost" onClick={() => {
                        (window as any)["modal_confirm_delete_influencer"].showModal();
                        setInfluencerIdToDelete(data.userId);
                    }}>🗑️</button>
                }
            },
        ];
    }

    return (
        <>

            <div className="mt-2">
                <div className="flex items-center gap-4">
                    <div>
                        <FormInput onChange={event => {
                            saveInfluencerList({isTemplate: event.target.checked})
                        }}
                                   name={'isTemplate'} type={'checkbox'}
                                   label={t("template")} checked={influencerList.isTemplate}/>
                    </div>
                    <div>
                        <FormInput onChange={event => {
                            saveInfluencerList({isArchived: event.target.checked})
                        }}
                                   name={'isArchived'} type={'checkbox'}
                                   label={t("archived")} checked={influencerList.isArchived}/>
                    </div>
                </div>

                <h2 className='text-2xl font-bold'>{t("filters")}</h2>


                <div className="flex gap-4">
                    <div className='w-1/3'>
                        <PlatformFilter filterInputChange={(e) => {
                            setFilterPlatform(e.target.value)
                        }} value={filterPlatform} name={'filter.platform'}/>
                    </div>

                    <div className='w-1/3'>
                        <FormInput
                            defaultValue="all"
                            type="select"
                            onChange={filterStatusChange}
                            name="filter.status"
                            value={filterStatus}
                            label={t("table-status")}>
                            <option value="all">{t("all-feminine")}</option>
                            {getInfluencerAvailableStatus().map(status => (
                                <option key={status.id} value={status.id}>
                                    {status.text}
                                </option>
                            ))}
                        </FormInput>
                    </div>
                </div>
                <div className='w-1/3 my-4'>
                    <FormInput onChange={event => {
                        setDatatableSearch(event.value as string);
                    }}
                                placeholder={t("search-by-username")}
                                name={'filterUsername'} type={'text'}
                                label={t("username")} value={datatableSearch} defaultValue={''}/>
                </div>

            </div>


            <DataTable
                sortable={true}
                paginate={true}
                background={["white", "light-2"]}
                step={15}
                onSort={setDatatabbleSorting}
                sort={datatableSorting}
                primaryKey="userId"
                columns={renderDatatable()}
                data={influencers.filter(influencer => {
                    if (filterStatus === 'all') return true;
                    const influencerState = influencerList?.influencersCategories.map(c => c.influencers).flat().find(i => i.userId === influencer.userId);
                    return influencerState?.status === filterStatus;
                }).filter(influencer => {
                    if (filterPlatform === 'none') return true;
                    const influencerState = influencerList?.influencersCategories.map(c => c.influencers).flat().find(i => i.userId === influencer.userId);

                    return influencerState?.network === filterPlatform
                }).filter(influencer => filterInfluencer(influencer))
                    .sort((a, b) => {
                        const influencerStateA = influencerList?.influencersCategories.map(c => c.influencers).flat().find(i => i.userId === a.userId);
                        const influencerStateB = influencerList?.influencersCategories.map(c => c.influencers).flat().find(i => i.userId === b.userId);

                        if (!influencerStateA || !influencerStateB) return 0;

                        if (!datatableSorting) return (influencerStateA?.fame as string).localeCompare((influencerStateB?.fame as string));

                        if (datatableSorting.property === "followers") {
                            if (datatableSorting.direction === "desc") {
                                // @ts-ignore
                                return b[(influencerStateB as any).network]?.profile.followers - a[(influencerStateA as any).network]?.profile.followers;
                            }
                            if (datatableSorting.direction === "asc") {
                                // @ts-ignore
                                return a[(influencerStateA as any).network]?.profile.followers - b[(influencerStateB as any).network]?.profile.followers;
                            }
                        } else if (datatableSorting.property === "engagement") {
                            if (datatableSorting.direction === "desc") {
                                // @ts-ignore
                                return b[(influencerStateB as any).network]?.profile.engagementRate - a[(influencerStateA as any).network]?.profile.engagementRate;
                            }
                            if (datatableSorting.direction === "asc") {
                                // @ts-ignore
                                return a[(influencerStateA as any).network]?.profile.engagementRate - b[(influencerStateB as any).network]?.profile.engagementRate;
                            }
                        } else if (datatableSorting.property === "fame") {
                            if (datatableSorting.direction === "asc") {
                                return (influencerStateA?.fame as string).localeCompare((influencerStateB?.fame as string));
                            }
                            return (influencerStateB?.fame as string).localeCompare((influencerStateA?.fame as string));
                        }

                        return (influencerStateA?.fame as string).localeCompare((influencerStateB?.fame as string));
                    })}
            />

            <InfluencerSearchDetailsModal
                name={influencerModalName}
                influencerId={selectedInfluencer?.userId as string}
                platform={influencerList?.influencersCategories.map(c => c.influencers).flat().find(i => i.userId === selectedInfluencer?.userId)?.network || influencerList.network}
                getFromFirebase={true}
                manageProfilAsClark={false}
                allowReload={true}
                influencerList={influencerList}
                showInModal={true}
                showInCustomerViewMode={false}
            />


            {selectedInfluencerId &&
                <InfluencerCommentsModal
                    name={commentModalName}
                    influencerList={influencerList}
                    influencerId={selectedInfluencerId}
                    showInCustomerViewMode={false}
                    onAddComment={(comment) => {
                        updateInfluencerList();
                        // slug && refreshInfos(slug)
                    }}
                />
            }

            <ConfirmMessage
                name="modal_confirm_delete_influencer"
                title={t("delete-confirm")}
                message={t("message-warning-suppress-list")}
                onSubmit={() => removeInfluencerFromList(influencerIdToDelete)}
            />
        </>
    );
}
