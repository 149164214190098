import React, {useEffect, useState} from 'react';

// Contexts
import {useAuth} from '../../../../contexts/AuthContext';

import CampaignsDatatable from "../../../datatables/CampaignsDatatable";
import {useFirebase} from "../../../../contexts/FirebaseContext";
import {Campaign, InfluencerList} from "../../../../types/dbModels";
import InfluencerListsDatatable from "../../../datatables/InfluencerListsDatatable";
import {useTranslation} from "react-i18next";

export default function Homepage() {
    const {currentUserData} = useAuth();
    const {t} = useTranslation();
    const {getCampaignsForUser, getInfluencerListForUser} = useFirebase();

    const [campaigns, setCampaigns] = useState<Campaign[]>([]);
    const [influencerLists, setInfluencerLists] = useState<InfluencerList[]>([]);

    useEffect(() => {
        refreshCampaigns();
        refreshInfluencerLists()
    }, []);

    const refreshCampaigns = async () => {
        let res = await getCampaignsForUser(currentUserData?.id, false);
        setCampaigns(res);
    }

    const refreshInfluencerLists = async () => {
        const aLists = await getInfluencerListForUser(currentUserData?.uid);
        setInfluencerLists(aLists);
    }
    return (
        <div className='my-4'>
            <div className="hero background-home">
                <div className="hero-content text-center flex flex-col">

                    {/* Welcome */}
                    <div className='mb-10'>
                        <h1 className="text-5xl font-bold">
                            {t("labels.hello")} {currentUserData?.firstname}
                        </h1>
                    </div>
                </div>


            </div>

            <div className="card bg-base-200">
                <div className="card-body">
                    <h1 className="text-3xl font-bold">⏳ {t("current-campaigns")}</h1>
                    <CampaignsDatatable campaigns={campaigns} refreshCampaigns={refreshCampaigns}/>
                </div>
            </div>

            <hr className="my-4" />

            <div className="card bg-base-200">
                <div className="card-body">
                    <h1 className="text-3xl font-bold">📂 {t("my-lists")}</h1>
                    <InfluencerListsDatatable influencerLists={influencerLists} refreshInfluencerLists={refreshInfluencerLists} showFilters={false} />
                </div>
            </div>
        </div>
    );
}
